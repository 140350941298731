import userService from "./userService";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("token") || null,
    userMenu: []
  },
  computed: {
    isAuthed: function() {
      return this.userId > 0;
    },
    userId: function() {
      return this.getters.userId;
    },
    userMenu: function() {}
  },
  getters: {
    jwt: state => state.token,
    jwtData: (state, getters) =>
      state.token ? JSON.parse(atob(getters.jwt.split(".")[1])) : null,
    jwtSubject: (state, getters) =>
      getters.jwtData ? getters.jwtData.sub : null,
    jwtIssuer: (state, getters) =>
      getters.jwtData ? getters.jwtData.iss : null,
    userId: (state, getters) => (getters.jwtData ? getters.jwtData.sub : 0),
    userId2: function(state, getters) {
      return getters.jwtData ? getters.jwtData.sub : 0;
    },
    isAuthed: function(state, getters) {
      if (getters.jwtData && getters.jwtData.sub) {
        return true;
      }
      return false;
    }
  },
  mutations: {
    logout: function(state) {
      state.token = null;
      localStorage.removeItem("token");
    },
    login: function(state, jwt) {
      state.token = jwt;
      localStorage.setItem("token", jwt);
    }
  },
  actions: {
    login({ commit, dispatch }, { email, password }) {
      return new Promise(function(resolve, reject) {
        userService
          .login(email, password)
          .then(function(jwt) {
            commit("login", jwt);
            resolve(jwt);
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },
    logout({ commit, dispatch }) {
      commit("logout");
      dispatch('profile/logout', {}, { root: true });
      /*dispatch(
        "notifications/success",
        { message: "Hasta pronto!" },
        { root: true }
      );*/
    },
    verifycode({ commit }, { email, password }) {
      return new Promise(function(resolve, reject) {
        userService
          .verifynewcode(email, password)
          .then(function(jwt) {
            resolve(jwt);
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },
    register({ commit, dispatch }, { email, password }) {
      return new Promise(function(resolve, reject) {
        userService
          .register(email, password)
          .then(function(jwt) {
            dispatch('notifications/success', {msg:'Registro exitoso'}, {root:true})
            resolve(jwt);
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },
    resetsave({ commit }, { code, email, password }) {
      return new Promise(function(resolve, reject) {
        userService
          .resetsave(email, password, code)
          .then(function(jwt) {
            resolve("OK");
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },
    verify({ commit }, { code, email, password }) {
      return new Promise(function(resolve, reject) {
        userService
          .verify(email, password, code)
          .then(function(jwt) {
            console.log('verify');
            commit("login", jwt);
            resolve(jwt);
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },
    reset({ commit, dispatch }, { email }) {
      return new Promise(function(resolve, reject) {
        userService
          .reset(email)
          .then(function() {
            dispatch('notifications/success', {
              msg: "Código enviado. Revise su correo electrónico."
            }, {root:true});
            resolve("");
          })
          .catch(function(err) {
            reject(err);
          });
      });
    }
  }
};
