<template>
  <div id="modal">
    <modal-title title="Seleccionar rubro principal"></modal-title>
    <!--button type="button" @click="close">Cerrar</button-->
    <add-relation-item
      :options="options"
      :exclude="exclude"
      @search-change="asyncFind"
      @selected="selected"
      buttonText="Seleccionar"
    ></add-relation-item>
  </div>
</template>

<script>
import { AddRelationItem } from "@linkedcode/common-lib";

export default {
  name: "CompanyTypeSelectModal",
  id: "company-type-select-modal",
  components: {
    AddRelationItem,
  },
  props: {
    itemId: {
        type: Number,
        required: true,
    },
    exclude: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      options: [],
      added: [],
    };
  },
  methods: {
    close() {
      this.resolve("ok");
    },
    asyncFind(text) {
      var self = this;
      this.$store
        .dispatch("companyType/search", { text: text })
        .then(function (res) {
          self.options = res;
        });
    },
    selected(option) {
      this.resolve(option);
      /*var self = this;
      this.$store
        .dispatch("company/addService", {
          service_id: option.id,
          company_id: this.itemId,
        })
        .then(function () {
          self.added.push(option);
        });*/
    }
  },
};
</script>