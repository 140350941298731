<template>
  <div id="estimate-add-company-type">
    <modal-title title="Agregar rubro"> </modal-title>
    <add-relation-item
      :options="options"
      :exclude="exclude"
      @search-change="asyncFind"
      @selected="selected"
    ></add-relation-item>
  </div>
</template>

<script>
import { AddRelationItem } from "@linkedcode/common-lib";

export default {
  name: "EstimateAddCompanyTypeModal",
  id: "estimate-add-company-type-modal",
  components: {
    AddRelationItem
  },
  props: {
    estimateId: {
        type: Number,
        required: true
    },
    exclude: {
        type: Array,
        default: function() {
            return [];
        }
    }
  },
  data() {
    return {
        options: []
    }
  },
  methods: {
    asyncFind(text) {
      if (text.length < 2) {
        return false;
      }
      var self = this;
      this.$store.dispatch('companyType/search', { text: text }).then(function(res){
        self.options = res;
      });
    },
    selected(type) {
      var body = { 
        estimate_id: this.estimateId,
        company_type_id: type.id
      }
      var self = this;
      this.$store.dispatch('estimates/addCompanyType', body).then(function(item){
      });
    }
  },
};
</script>