<template>
  <div id="estimate-detail" v-if="item">
    <page-title :title="item.subject">
    </page-title>
    <page-content>
      <h2>Resumen</h2>
      <div v-html="abstract">
      </div>
      <h2>Descripción</h2>
      <div v-html="formatted">
      </div>
      <h2>Objetivos</h2>

      <h3>Rubros</h3>
      <ul class="unstyled">
        <li v-for="company in item.company_types" :key="company.id">{{company.name}}</li>
        <li v-if="item.company_types.length == 0">No hay rubros seleccionados</li>
      </ul>
      <h3>Servicios</h3>
      <ul class="unstyled">
        <li v-for="service in item.service_types" :key="service.id">{{service.name}}</li>
        <li v-if="item.service_types.length == 0">No hay servicios seleccionados</li>
      </ul>
    </page-content>
  </div>
</template>

<script>

export default {
  name: "EstimateDetail",
  components: { 
  },
  computed: {
    item() {
      return this.$store.state.estimates.active;
    }, 
    formatted() {
      return this.item.description.replaceAll('\n', '<br/>');
    },
    abstract() {
      return this.item.abstract.replaceAll('\n', '<br/>');
    }
  },
  methods: {
    load(id) {
      this.$store.dispatch('estimates/load', { estimate_id: id});
    }
  },
  mounted() {
    //console.log(this.$route);
    this.load(this.$route.params.estimate_id);
  }
};
</script>
