<template>
  <div id="companies">
    <div class="page-title">
      <h1>Companies</h1>
      <div class="actions">
        <button class="primary" type="button" @click="create()">Nuevo</button>
      </div>
    </div>
    <div class="grid-list-filters">
      <input class="form-control" type="text" v-model="text" />
      <button class="btn btn-primary" type="button" @click="filter()">Filtrar</button>
    </div>
    <ul class="grid-list">
      <li v-for="c in companyList" :key="c.id">
        <div class="company-list-item-name">{{ c.id }}</div>
        <div class="">
          <router-link :to="{ name:'company-detail', params: {company_id: c.id}}">
            {{ c.name }}
          </router-link>
          
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {
  },
  computed: {
    companyList() {
      return this.$store.state.company.list;
    }
  },
  data() {
    return {
      text: ''
    }
  },
  watch:{
    $route (to, from){
      this.load(to.query);
    }
  },
  methods: {
    filter() {
      var q = {};
      Object.assign(q, this.$route.query, { text: this.text });
      this.$router.push({ name: 'companies', query: q });
    },
    create() {
      this.$router.push({name: 'company-new'});
    },
    edit(company_id) {
      this.$router.push({name: 'company-edit', params: { company_id: company_id} });
    },
    load(query) {
      this.text = query.text;
      this.$store.dispatch('company/list', query);
    }
  },
  mounted() {
    this.load(this.$route.query);
  }
}
</script>
<style>
#companies .grid-list-filters {
  grid-template-columns: 1fr min-content;
}
#companies .grid-list li {
  grid-template-columns: 1fr 5fr 1fr;
}
</style>