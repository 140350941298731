//import { base } from "@linkedcode/axios-plugin";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    add(state, data) {
      state.items.push(data);
    }
  },
  actions: {
    add({commit}, setting) {
      commit('add', setting);
    }
  }
}