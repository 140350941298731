<template>
  <div id="my-estimates">
    <page-title title="Mis Presupuestos">
      <template v-slot:actions>
        <button class="primary" @click="create()">Nuevo</button>
      </template>
    </page-title>
    <div class="page-content">
      <ul class="grid-list">
        <li v-for="e in estimates" :key="e.id">
          <div>
            <div class="dt">Asunto</div>
            <router-link
              :to="{ name: 'detail', params: { estimate_id: e.id } }"
            >
              {{ e.subject }}
            </router-link>
          </div>
          <div class="actions">
            <button @click="preview(e.id)">Vista previa</button>
            <button @click="notifications(e.id)">Notificaciones</button>
            <button @click="edit(e.id)">Editar</button>
            <!--button @click="forecast(e.id)">Editar</button-->
            <!--router-link :to="{name:'estimate-notifications',params:{estimate_id: e.id}}">Ver notificaciones enviadas</router-link-->
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Estimate",
  computed: {
    estimates() {
      return this.$store.state.estimates.own;
    }
  },
  components: {
  },
  methods: {
    newEstimate() {
      this.$router.push({ 'name': 'estimate-new' });
    },
    notifications(estimateId) {
      this.$router.push({ name: 'estimate-notifications', params: { estimate_id: estimateId } });
    },
    preview(estimateId) {
      this.$router.push({ name: 'estimate-preview', params: { estimate_id: estimateId } });
    },
    load() {
      this.$store.dispatch("estimates/me");
    },
    forecast(id) {
      this.$store.dispatch("estimates/forecast", { id: id });
    },
    edit(id) {
      this.$router.push({ 'name': 'estimate-edit', params: { estimate_id: id } });
    },
    create() {
      this.$router.push({ 'name': 'estimate-new' });
    }
  },
  mounted() {
    this.load();
  }
};
</script>