<template>
  <div id="my-companies">
    <div class="page-title">
      <h1>Mis empresas</h1>
      <div class="actions">
        <button type="button" @click="create">Agregar empresa</button>
      </div>
    </div>
    <ul class="grid-list">
      <li v-for="c in companies" :key="c.id">
        <div>{{c.name}}</div>
        <div class="actions">
          <button type="button" @click="edit(c.id)">Editar</button>
        </div>
      </li>
      <li v-if="companies.length === 0">
        No tienes empresas. 
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "MyCompaniesView",
  computed: {
    companies() {
      return this.$store.state.company.own;
    }
  },
  methods: {
    create() {
      this.$router.push({name:'company-new'});
    },
    load() {
      this.$store.dispatch('company/own');
    },
    edit(id) {
      this.$router.push({name:'company-edit', params: {company_id: id}});
    }
  },
  mounted() {
    this.load();
  },
};
</script>