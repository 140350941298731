<template>
  <VueMultiselect
    v-model="selected"
    :options="available"
    :multiple="false"
    :searchable="searchable"
    @search-change="asyncFind"
    @select="onSelect"
    placeholder="Type to search"
    label="name"
    track-by="id"
  >
    <span slot="noResult">
      Oops! No elements found. Consider changing the search query.
    </span>
  </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect'

export default {
  name: "CompanyTypeSelect",
  data() {
    return {
      available: [],
      searchable: true,
      selected: []
    }
  },
  components: {
    VueMultiselect
  },
  methods: {
    asyncFind(text) {
      
    },
    onSelect(selectedOption) {
      //console.log(selectedOption, id)
      this.$emit('evento', selectedOption);
    },
    notify() {
      var self = this;
      this.$watch('selectedServices', () => {
        self.$emit('evento', this.selected);
      })
    }
  },
  created() {
    
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>