import SystemArea from "./SystemArea.vue";
import store from "./store";
import { markRaw } from 'vue'

export default {
  installed: false,
  install(app, options) {
    if (this.installed) return;


    if (typeof options == 'undefined' || typeof options.store == 'undefined') {
      console.warn("options.store is required")
    }

    //app.config.globalProperties.$store.registerModule("systemArea", store);
    options.store.registerModule("systemArea", store);
    
    app.component("system-area", SystemArea);

    const systemArea = {
      add: function(applet) {
        //app.config.globalProperties.$store.dispatch("systemArea/add", applet);
        //console.log(applet);
        options.store.dispatch("systemArea/add", markRaw(applet));
      }
    };

    if (options && options.applets) {
      options.applets.forEach(function(applet) {
        systemArea.add(applet);
      }, this);
    }

    app.config.globalProperties.$systemArea = systemArea;

    this.installed = true;
  }
};
