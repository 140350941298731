<template>
  <div id="register-verify-modal">
    <h2>Verificación de correo electrónico</h2>
    <form>
      <div class="alert alert-info" role="alert" v-if="errors.length">
        <div v-for="err in errors" v-bind:key="err.id">{{ err }}</div>
      </div>
      
        <label for="email">Codigo</label>
        <input
          type="text"
          v-model="code"
          placeholder="Codigo enviado al email"
        />
      <div class="button-group">
        <button
          type="button"
          v-on:click="cancel()"
        >
          Cancelar
        </button>
        <button type="button" @click="verify()" class="primary">Verificar</button>
      </div>
      
      <p>Si el código enviado al correo electrónico no llegó, y ya reviso la carpeta Spam, puede solicitar un nuevo código:</p>
      <div>
        <button type="button" class="primary" @click="newcode()">Solicitar nuevo codigo</button>
      </div>
    </form>
  </div>
</template>

<script>
//import store from "@/store";

export default {
  name: "RegisterVerifyModal",
  id: "register-verify-modal",
  data: function() {
    return {
      errors: [],
      code: ""
    };
  },
  props: {
    password: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  methods: {
    cancel() {
      this.reject("Cancelled");
    },
    newcode() {
      let self = this;

      this.$store
        .dispatch("auth/verifycode", {
          email: self.email,
          password: self.password
        })
        .then(function(msg) {
          //self.resolve(msg);
          //console.log(msg);
        })
        .catch(function(errors) {
          self.errors = errors;
        });
    },
    verify() {
      this.errors = [];
      let self = this;

      this.$store
        .dispatch("auth/verify", {
          email: self.email,
          password: self.password,
          code: self.code
        })
        .then(function(msg) {
          self.resolve(msg);
        })
        .catch(function(errors) {
          self.errors = errors;
        });
    }
  }
};
</script>
