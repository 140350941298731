import { base } from "@linkedcode/axios-plugin";

export default {
  namespaced: true,
  state: {
    all: [],
    unseen: []
  },
  getters: {
    
  },
  mutations: {
    setAll(state, data) {
      state.all = data;
    },
    setUnseen(state, data) {
      state.unseen = data;
    },
    markAsSeen(state, data) {
      state.all.forEach(function(not) {
        if (not.id == data.notification_id) {
          not.seen = 1;
        }
      });

      state.unseen.forEach(function(not, idx) {
        if (not.id == data.notification_id) {
          state.unseen.splice(idx, 1);
        }
      });
    }
  },
  actions: {
    all({ commit }, data) {
      return new Promise(function(resolve, reject) {
        base.get(`/notifications`).then(function (notif) {
          commit('setAll', notif.data);
          resolve(notif.data);
        })
      })
    },
    unseen({ commit }, data) {
      return new Promise(function(resolve, reject) {
        base.get(`/notifications`, { params: { seen: 0 }}).then(function (notif) {
          commit('setUnseen', notif.data);
          resolve(notif.data);
        })
      })
    },
    markAsSeen({ commit }, data) {
      return new Promise(function(resolve, reject) {
        base.put(`/notifications/${data.notification_id}/seen`).then(function (notif) {
          commit('markAsSeen', data);
          resolve(notif.data);
        })
      })
    }
  }
};
