import NewPassModal from "./NewPassModal.vue"
import RegisterForm from "./component/RegisterForm.vue"
import RegisterVerifyModal from "./RegisterVerifyModal.vue"
import ResetModal from "./ResetModal.vue"
import UserAppletSidebar from "./component/UserAppletSidebar.vue";
import UserProfile from "./views/UserProfile.vue";
import store from "./store";
import profileStore from "./stores/ProfileStore";
import notificationStore from "./stores/notification";
import settingsStore from "./stores/settings";
import ResetView from "./views/ResetView.vue";
import RegisterView from "./views/RegisterView.vue";

import LoginFormModal from "./component/LoginFormModal.vue";

export default {
  installed: false,
  install(app, options) {
    if (typeof options == 'undefined') {
        console.warn('AuthPlugin necesita declarar options (store, router)')
    };
    //console.log(app.config.globalProperties.$openModal);
    if (this.installed) return;

    //app.config.globalProperties.$systemArea.add(UserApplet);
    app.component('user-applet-sidebar', UserAppletSidebar);
    //app.component('user-applet', UserApplet);
    
    //app.config.globalProperties.$store.registerModule("auth", store);
    options.store.registerModule("auth", store);
    options.store.registerModule("profile", profileStore);
    options.store.registerModule("notification", notificationStore);
    options.store.registerModule("settings", settingsStore);

    const routes = [
      { path: '/profile', component: UserProfile },
      { path: '/login', name: "auth-login", component: LoginFormModal },
      { path: '/reset', name:"auth-reset", component: ResetView },
      { path: '/register', name: "auth-register", component: RegisterView }
    ];

    routes.forEach(function(route) {
      options.router.addRoute(route);
    });

    const auth = {
      loginModal: function() {
       return app.config.globalProperties.$openModal({
          component: LoginFormModal,
          //cssClass: ["open-on-right"]
        });
      },
      registerModal: function() {
        return app.config.globalProperties.$openModal({
          component: RegisterForm
        });
      },
      resetModal: function() {
        return app.config.globalProperties.$openModal({
          component: ResetModal,
        });
      },
      newPassModal: function({ props }) {
        return app.config.globalProperties.$openModal({
          component: NewPassModal,
          props: props
        });
      },
      verifyModal: function({ props }) {
        return app.config.globalProperties.$openModal({
          component: RegisterVerifyModal,
          props: props
        });
      },
      isAuthenticated: function() {
        return options.store.getters["auth/isAuthed"];
      },
      isAuth: function() {
        return this.isAuthenticated();
      },
      isAuthed: function() {
        return this.isAuthenticated();
      }
    };

    options.router.beforeEach((to, from, next) => {
      //console.log("beforeEach", from, to);
    
      if (to.name === "home") {
        if (from.name !== null) {
          options.store.dispatch("apps/activate", to.meta.app);
        }
      }
    
      if (from.meta.app !== to.meta.app) {
        // Si activo lleva a la ruta "default"
        //console.log(router);
        options.store.dispatch("apps/change", to.meta.app);
      }
    
      if (to.matched.some(record => record.meta.requiresAuth)) {
        //console.log("requiresAuth");
        if (auth.isAuthenticated()) {
          //console.log("Authentincated");
          next();
        } else {
          //console.log("Not authentincated");
          let instance = auth.loginModal();
          instance
            .then(function(message) {
              //console.log("auth.loginModal:OK", message);
              next();
            })
            .catch(function(message) {
              //console.log("auth.loginModal:ERR", message);
              next(false);
            });
          //options.router.push('/login');
          //next(false);
        }
      } else {
        next();
      }
    });

    app.config.globalProperties.$auth = auth;
    
    this.installed = true;
  }
};

export { UserProfile };