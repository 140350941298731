<template>
  <VueMultiselect
    v-model="selectedServices"
    :options="availableServices"
    :multiple="false"
    :searchable="searchable"
    @search-change="asyncFind"
    @select="onSelect"
    placeholder="Type to search"
    label="name"
    track-by="id"
  >
    <span slot="noResult">
      Oops! No elements found. Consider changing the search query.
    </span>
  </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect'

export default {
  data() {
    return {
      availableServices: [],
      searchable: true,
      selectedServices: []
    }
  },
  components: {
    VueMultiselect
  },
  methods: {
    asyncFind(text) {
      if (text.length < 2) {
        return false;
      }
      var self = this;
      this.$store.dispatch('services/search', { text: text }).then(function(res){
        self.availableServices = res;
      });
    },
    onSelect(selectedOption) {
      //console.log(selectedOption, id)
      this.$emit('evento', selectedOption);
    },
    notify() {
      var self = this;
      this.$watch('selectedServices', () => {
        self.$emit('evento', this.selectedServices);
      })
    }
  },
  created() {
    
  }
}
</script>

<!--style src="vue-multiselect/dist/vue-multiselect.css"></style-->