<template>
  <div class="container">
    <div class="d-flex">
      <h2>Mis direcciones</h2>
      <div class="ms-auto">
        <button class=" btn btn-outline-primary" @click="addAddress">Agregar dirección</button>
      </div>
    </div>
    <div class="border-top py-3" v-for="a in addresses" v-bind:key="a.id">
      <div class="row">
        <div class="col-3">
          <div class="dt">Titulo</div>
          <div>{{a.title}}</div>
        </div>
        <div class="col-6">
          <div class="dt">Dirección</div>
          <div>{{format(a)}}</div>
        </div>
        <div class="col-3 text-end">
          <button type="button" class="btn btn-primary" v-on:click="edit(a)">Editar</button>
          <!--button type="button" class="btn btn-primary" v-on:click="remove(a.id)"><i class="bi bi-trash"></i></button-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

class Address {
  constructor(obj) {
    this.o = obj;
    console.log(obj);
  }
  toDTO() {
    return {
      title: this.o.title || "",
      description: this.o.description || "",
      street: this.o.street || "",
      number: this.o.number,
      floor: this.o.floor,
      department: this.o.department,
      country_id: this.o.country.id,
      province_id: this.o.province.id,
      city_id: this.o.city.id
    }
  }
}

import AddressAddEditForm from "../components/AddressAddEditForm.vue";

export default {
  name: "MyAddresses",
  id: "my-addresses",
  components: {
    AddressAddEditForm
  },
  computed: {
    addresses() {
      return this.$store.state.address.me;
    }
  },
  methods: {
    addAddress() {
      var self = this;
      this.$openModal({
        component: AddressAddEditForm
      }).then(function() {
        self.load();
      }).catch(function(b){
        console.log(b);
      });
    },
    edit(address) {
      //var ad = new Address(address);
      //console.log(ad);
      //var addressDTO = ad.toDTO();
      //console.log(addressDTO);
      /*this.$openModal({
        component: AddressEditForm,
        props: { item: address }
      });*/
      this.$openModal({
        component: AddressAddEditForm,
        width: 50,
        props: { address: address }
      }).then(function(a) {
        console.log(a);
      }).catch(function(b){
        console.log(b);
      });;
    },
    remove(id) {
      var self = this;
      this.$store.dispatch('address/remove', { address_id: id }).then(function(){
        //this.load();
      });
    },
    format(address) {
      var line = '';
      if (address.street) {
        line += address.street;
      }
      if (address.number) {
        line += ' ' + address.number;
      }
      if (address.floor) {
        line += ' ' + address.floor;
      }
      if (address.department) {
        line += ' ' + address.department;
      }
      if (address.city) {
        line += ', ' + address.city.name;
      }
      if (address.province) {
        line += ', ' + address.province.name;
      }
      if (address.country) {
        line += ', ' + address.country.name;
      }
      return line;
    },
    load() {
      this.$store.dispatch('address/load');
    }
  },
  mounted() {
    this.load();
  }
}
</script>