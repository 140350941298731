import axios from "axios";
//import { loadProgressBar } from 'axios-progress-bar'

//console.log("A");
const base = axios.create();

//loadProgressBar({}, base)

export default {
  installed: false,
  install(app, options) {
    if (this.installed) {
      return;
    }

    base.defaults.baseURL = options.api.url;
    //console.log("axios plugin",base.defaults);
    //base.defaults.withCredentials = true;

    base.interceptors.request.use(config => {
      //console.log('request use');
      if (config.url.indexOf("/auth/") !== -1) {
        //config.baseURL = options.api.auth;
      }
      //console.log(config);

      let t = localStorage.getItem("token") || null;

      if (t) {
        config.headers.common["Authorization"] = `Bearer ${t}`;
      }

      return config;
    });

    base.interceptors.response.use(response => {
        if (!response.config.url.endsWith("/login")) {
          //Vue.prototype.$Progress.finish();
        }

        return response;
      },
      function(error) {
        var isLogin = error.request.responseURL.endsWith("/login");

        if (typeof error.response === 'undefined') {
          //console.log(error.message);
          return Promise.reject(error);
        }

        console.log(error);
        if (error.response.status === 401 && isLogin === false) {
          return new Promise(function(resolve, reject) {
            let instance = app.config.globalProperties.$auth.loginModal();
            let config = error.config;

            instance
              .then(function(data) {
                let t = localStorage.getItem("token");
                config.headers["Authorization"] = `Bearer ${t}`;
                resolve(base.request(config));
              })
              .catch(function(err) {
                reject(err);
              });
          });
        } else {
          return Promise.reject(error);
        }
      }
    );

    app.config.globalProperties.$http = base;
    options.store.$http = base;

    this.installed = true;
  }
};

//console.log("B", base);

export { base };
