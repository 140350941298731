<template>
  <div id="estimate-preview">
    <page-title title="Vista Previa">
      <template v-slot:actions>
        <button type="button" class="primary" @click="prepare()">Preparar notificaciones</button>
      </template>
    </page-title>
    <page-content>
      <ul v-if="contacts.length > 0" class="grid-list">
        <li v-for="c in contacts" :key="c.id">
          {{ c.name }}
        </li>
        <li v-if="contacts.length === 0">No hay coincidencias</li>
      </ul>
    </page-content>
  </div>
</template>

<script>
export default {
  name: "EstimatePreview",
  data() {
    return {
      contacts: []
    }
  },
  computed: {
    estimateId() {
      console.log(this.$route);
      return this.$route.params.estimate_id;
    }
  },
  methods: {
    prepare() {
      var self = this;
      this.$store.dispatch('estimates/prepare', {
        estimate_id: this.estimateId
      }).then(function(){
        self.$router.push({name:'estimate-notifications'});
      });
    }
  },
  mounted() {
    var self = this;
    this.$store
      .dispatch('estimates/preview', {estimate_id: this.estimateId})
      .then(function(results) {
        console.log(results);
        self.contacts = results;
      })
  }
}
</script>