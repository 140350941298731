<template>
  <header>
    <hamburger></hamburger>
    <div id="brand">
      <router-link class="navbar-brand" to="/">Linkedcode</router-link>
    </div>
    <navbar-menu />
    <system-area></system-area>
  </header>
  <router-view class="main-wrapper" />
  <footer>Linkedcode</footer>
  <layer-modal></layer-modal>
</template>
