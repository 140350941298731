import Home from "./views/Home.vue";
import Estimates from "./views/Estimates.vue";
import EstimateDetail from "./views/EstimateDetail.vue";
import EstimateNew from "./views/EstimateNew.vue";
import EstimateNotifications from "./views/EstimateNotifications.vue";
import EstimateMessageList from "./views/EstimateMessageList.vue";
import EstimatePreview from "./views/EstimatePreview.vue";
import MyEstimates from "./views/MyEstimates.vue";

import store from "./store";
import servicesStore from "./servicesStore";

const estimateDetail = {
  path: ":id",
  name: "estimate-detail",
  component: EstimateDetail,
  children: {
    path: "notifications",
    name: "notifications",
    component: EstimateNotifications,
    meta: {
      requiresAuth: false
    }
  },
  meta: {
    requiresAuth: false
  }
}

export default {
  installed: false,
  install(app) {
    if (this.installed) return;
    this.installed = true;
  },
  name: "Presupuestos",
  route: "",
  vuex: [
    {
      name: 'estimates',
      module: store
    },
    {
      name: 'services',
      module: servicesStore
    }
  ],
  component: "Home",
  menu: [{
    title: "Home",
    id: "home",
    route: {
      name: "home"
    }
  },{
    title: "Presupuestos",
    id: "estimates",
    route: {
      name: "estimates"
    },
    menu: [{
      title: "Presupuestos",
      id: "estimates",
      route: "estimates"
     }, {
      title: "Mis Presupuestos",
      id: "my-estimates",
      route: "my-estimates"
    }]
  },{
    title: "Empresas",
    id: "companies",
    menu: [{
      title: "Empresas",
      route: "companies"
    }, {
      title: "Mis Empresas",
      route: "my-companies"
    }]
  }],
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        title: "Home",
        requiresAuth: false
      }
    },
    {
      path: "/estimates",
      name: "estimates",
      component: Estimates,
      meta: {
        requiresAuth: false
      },
      children: []
    },
    {
      path: "/estimates/:estimate_id",
      name: "detail",
      component: EstimateDetail,
      children: [],
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: "/my/estimates",
      name: "my-estimates",
      component: MyEstimates,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: "/estimates/:estimate_id/notifications",
      name: "estimate-notifications",
      component: EstimateNotifications,
      children: [],
      meta: {
        requiresAuth: false,
        transition: 'slide-fade',
      }
    },
    {
      path: "/estimates/:estimate_id/notifications/:notification_id/messages",
      name: "estimate-message-list",
      component: EstimateMessageList,
      meta: {
        requiresAuth: false,
        transition: 'slide-fade',
      }
    },
    {
      path: "/estimates/:estimate_id/notifications",
      name: "estimate-notifications",
      component: EstimateNotifications,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/estimates/:estimate_id/preview",
      name: "estimate-preview",
      component: EstimatePreview,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/estimates/new",
      name: "estimate-new",
      component: EstimateNew,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/estimates/:estimate_id/edit",
      name: "estimate-edit",
      component: EstimateNew,
      meta: {
        requiresAuth: false
      }
    },
    
  ]
};
