<template>
  <div class="modal-window">
    <h2>Resetear contraseña</h2>
    <p><small>Para resetear la contraseña ingrese su correo electrónico y se le enviará un código que deberá introducir en la pantalla siguiente.</small></p>
    <reset-form></reset-form>
  </div>
</template>

<script>
import ResetForm from "./component/ResetForm.vue";

export default {
  name: "ResetForm",
  id: "reset-form",
  components: {
    ResetForm
  }
}
</script>