<template>
    <div id="company-detail" v-if="company">
        <page-title :title="company.name">
        </page-title>
        <page-content>
            <div class="description">
                {{ company.description }}
            </div>
            <div class="address">
                <h2>Información general</h2>
                <dl>
                    <dt>Rubro:</dt>
                    <dd v-if="company.type">{{ company.type.name }}</dd>
                    <dd v-else>No especificado</dd>
                    <dt>Telefono:</dt>
                    <dd>{{ company.phone }}</dd>
                    <dt>Sitio web:</dt>
                    <dd>{{ company.web }}</dd>
                    <dt>Dirección</dt>
                    <dd>
                        <div class="address-content" v-if="company.address">
                        {{ company.address.street }}
                        <span>{{ company.address.number }}</span>&nbsp;
                        <span v-if="company.address.floor">Piso {{ company.address.floor }}</span>&nbsp;
                        <span v-if="company.address.department">Depto. {{ company.address.department }}</span>
                        <div class="address-region">
                            <span>{{ company.address.province.name }}</span>, <span>{{ company.address.country.name }}</span>
                        </div>
                        </div>
                        <span v-else>No se ha asignado una dirección</span>
                    </dd>
                </dl>
                <h2>Servicios</h2>
                <ul>
                    <li v-for="s in company.services">{{ s.name }}</li>
                </ul>

            </div>
        </page-content>
    </div>
</template>
<script lang="js">
export default {
    name: "CompanyDetail",
    data() {
        return {
            company: null
        }
    },
    computed: {
        companyId() {
            return this.$route.params.company_id;
        }
    },
    methods: {
        
        load() {
            var self = this;
            this.$store.dispatch("company/load", {company_id: this.companyId}).then(function(company){
                self.company = company;
            })
        }
    },
    mounted() {
        this.load();
    }
}
</script>