<template>
  <div id="company-add-edit">
    <h1>Crear empresa</h1>
    <form>
      <fieldset>
        <legend>Informacion general</legend>
        <label>Nombre</label>
        <input type="text" v-model="item.name" />
        <label>Descripcion</label>
        <input type="text" v-model="item.description" />
        <label>Pagina web</label>
        <input type="text" v-model="item.web" />
        <label>Correo electronico</label>
        <input type="text" v-model="item.email" />
        <label>Rubro</label>
        <div class="input-group">
          <input type="text" v-model="type.name" readonly @click="companyTypeSelect" />
          <button type="button" @click="companyTypeSelect">Seleccionar</button>
        </div>
      </fieldset>
      <fieldset>
        <legend>Dirección</legend>
        <label>Seleccione o cree una direccion</label>
        <address-select
          :selected="item.address_id"
          @selectedAddress="updateAddressId"
        ></address-select>
      </fieldset>
    
      <section v-if="companyId">
        <section-wrapper>
          <section-title title="Servicios">
            <template v-slot:actions>
              <button type="button" class="primary" @click="addServiceModal">Agregar</button>
            </template>
          </section-title>
          <tag-list :tags="services" @remove="removeService"></tag-list>
        </section-wrapper>
      </section>
      <div class="actions">
        <button class="primary" type="button" @click="submit">Guardar</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ServicesSelect } from "@linkedcode/common-lib";
import ServicesSelectModal from "./../components/ServicesSelectModal.vue";
import CompanyTypeSelectModal from "../components/CompanyTypeSelectModal.vue";

export default {
  data() {
    return {
      loaded: false,
      services: [],
      type: {
        id: 0,
        name: ""
      },
      item: {
        name: "",
        description: "",
        address_id: 0,
        company_type_id: 0
      },
    };
  },
  computed: {
    companyId() {
      if (this.$route.params.company_id) {
        return this.$route.params.company_id;
      }
      return false;
    },
  },
  components: {
    ServicesSelect
  },
  methods: {
    companyTypeSelect() {
      var self = this;
      this.$openModal({
        component: CompanyTypeSelectModal,
        props: {
          itemId: parseInt(this.companyId),
          exclude: [this.type]
        }
      }).then(function (res) {
        //console.log(res);
        self.type = res;
        self.item.company_type_id = res.id;
      })
    },
    addServiceModal() {
      var self = this;
      this.$openModal({
        component: ServicesSelectModal,
        props: {
          itemId: parseInt(this.companyId),
          exclude: this.services
        }
      }).then(function(res) {
        //self.reloadServices();
      }).catch(function(res) {
        //self.reloadServices();
      })
    },
    create() {
      this.$router.push({ name: "company-new" });
    },
    submit() {
      var self = this;
      if (this.companyId) {
        var payload = {
          item: this.item,
          company_id: this.companyId,
        };
        this.$store.dispatch("company/update", payload).then(function() {
          self.$router.push({name:'my-companies'});
        });;
      } else {
        let payload = { item: this.item };
        this.$store.dispatch("company/create", payload).then(function() {
          self.$router.push({name:'my-companies'});
        });
      }
    },
    updateAddressId(id) {
      console.log("updateAddressId", id);
      this.item.address_id = id;
    },
    removeFromRow(items, item) {
      items.forEach(function (i, idx) {
        if (i.id == item.id) {
          items.splice(idx, 1);
        }
      });
      return items;
    },
    removeService(service) {
      var self = this;
      this.$store
        .dispatch("company/removeService", {
          service_id: service.id,
          company_id: this.companyId,
        })
        .then(function (res) {
          console.log("remove", res, service);
          self.services = self.removeFromRow(self.services, service);
        });
    },
    reloadServices() {
      var self = this;
      this.$store.dispatch('company/reloadServices', { company_id: this.companyId})
      .then(function(services){
        self.services = services;
      })
    },
    load() {
      if (this.companyId === false) {
        return;
      }
      var self = this;
      this.$store
        .dispatch("company/load", { company_id: this.companyId })
        .then(function (item) {
          self.item.name = item.name;
          self.item.description = item.description;
          self.item.address_id = item?.address?.id ?? 0;
          self.item.web = item.web;
          self.item.email = item.email;
          self.services = item.services ?? [];
          self.type = item.type;
          self.item.company_type_id = item.type.id ?? 0;
          self.loaded = true;
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style>
.service-item {
  display: flex;
  gap: 1rem;
}
.service-item-name {
  flex-grow: 2;
}
.service-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;
}
</style>