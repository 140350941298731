<template>
  <div id="header-menu">
    <ul class="menu">
      <template v-for="item in menu">
        <li v-if="!item.menu" :key="item.name">
          <a v-if="item.method" @click="emit(item.method)">
            <i v-if="item.icon" :class="item.icon"></i>
            <span v-if="item.title">{{ item.title }}</span>
            <span v-if="item.badge" class="badge">{{item.badge}}</span>
          </a>
          <router-link v-if="item.route" :to="{ name: item.route.name }">
            <i v-if="item.icon" :class="item.icon"></i>
            <span v-if="item.title">{{ item.title }}</span>
            <span v-if="item.badge" class="badge">{{item.badge}}</span>
          </router-link>
        </li>
        <menu-dropdown @emit="emit" :key="item.name" v-if="item.menu" :item="item"></menu-dropdown>
      </template>
    </ul>
  </div>
</template>

<script>
import MenuDropdown from './MenuDropdown.vue';

export default {
  id: "menu-base",
  name: "MenuBase",
  data: function() {
    return {
      selected: null
    }
  },
  props: {
    menu: {
      type: Array
    }
  },
  components: {
    MenuDropdown
  },
  methods: {
    emit(v) {
      console.log('emit', v);
      this.$emit(v);
    },
    select(id) {
      if (this.selected === id) {
        this.selected = null;
      } else {
        this.selected = id;
      }
    }
  }
};
</script>
