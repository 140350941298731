<template>
  <div id="estimate-new">
    <div class="page-title">
      <h1>Editar</h1>
    </div>

    <form v-if="item">
      <fieldset>
        <legend>Información básica</legend>
          <label>Asunto</label>
          <input class="form-control" type="text" v-model="item.subject" />
          <label>Resumen</label>
          <textarea class="form-control" v-model="item.abstract"></textarea>
          <label>Descripcion Larga</label>
          <textarea class="form-control" v-model="item.description"></textarea>
          <label>Estado</label>
          <select v-model="item.status.id">
            <option value="0">Pausado</option>
            <option value="1">Activo</option>
            <option value="2">Cerrado</option>
          </select>
        </fieldset>
        <div v-if="editing">
          <section-wrapper>
            <section-title title="Servicios">
              <template v-slot:actions>
                <button type="button" class="primary" @click="addServiceModal">Agregar</button>
              </template>
            </section-title>
            <tag-list :tags="item.service_types" @remove="removeService"></tag-list>
          </section-wrapper>

          <section-wrapper>
            <section-title title="Rubros">
              <template v-slot:actions>
                <button type="button" class="primary" @click="addCompanyTypeModal">Agregar</button>
              </template>
            </section-title>
            <tag-list :tags="item.company_types" @remove="removeCompanyType"></tag-list>
          </section-wrapper>

        </div>
        <div class="actions">
          <button type="button" class="primary" @click="submit()">Guardar</button>
        </div>
    </form>
  </div>
</template>

<script>
import { ServicesSelect } from "@linkedcode/common-lib"
import CompanyTypeSelect from "../components/CompanyTypeSelect.vue"
import EstimateAddServiceTypeModal from "../components/EstimateAddServiceTypeModal.vue"
import EstimateAddCompanyTypeModal from "../components/EstimateAddCompanyTypeModal.vue"

export default {
  name: "EstimateNew",
  data() {
    return {
      item: {
        subject: '',
        description: '',
        abstract: '',
        service_types: [],
        company_types: [],
        status: {
          id: 0
        }
      }
    }
  },
  components: { 
    ServicesSelect,
    CompanyTypeSelect
  },
  computed: {
    estimateId() {
      if (this.$route.params.estimate_id) {
        return parseInt(this.$route.params.estimate_id);
      }
      return false;
    },
    editing() {
      if (this.estimateId) {
        return true;
      }
      return false;
    }
  },
  methods: {
    addServiceModal() {
      this.$openModal({
        component: EstimateAddServiceTypeModal,
        props: {
          estimateId: this.estimateId,
          exclude: this.item.service_types
        }
      })
    },
    addCompanyTypeModal() {
      this.$openModal({
        component: EstimateAddCompanyTypeModal,
        props: {
          estimateId: this.estimateId,
          exclude: this.item.company_types
        }
      })
    },
    load(id) {
      var self = this;
      this.$store.dispatch('estimates/load', { estimate_id: id}).then(function(item){
        console.log(item);
        self.item = item;
        /*.subject;
        self.item.abstract = item.abstract;
        self.item.description = item.description;
        self.item.services = item.services;
        self.item.company_types = item.company_types;
        self.item.service_types = item.service_types;*/
      });
    },
    removeService(serviceId) {
      var body = { 
        estimate_id: this.estimateId,
        service_id: serviceId
      }
      var self = this;
      this.$store.dispatch('estimates/removeService', body).then(function(item){
        self.load(self.estimateId);
      });
    },
    removeCompanyType(companyTypeId) {
      var body = { 
        estimate_id: this.estimateId,
        company_type_id: companyTypeId
      }
      var self = this;
      this.$store.dispatch('estimates/removeCompanyType', body).then(function(item){
        self.load(self.estimateId);
      });
    },
    submit() {
      var self = this;
      var item = {
        subject: this.item.subject,
        abstract: this.item.abstract,
        description: this.item.description,
        status: this.item.status.id,
      };

      if (this.estimateId) {
        var data = { 
          item: item,
          estimateId: this.estimateId 
        };
        this.$store.dispatch("estimates/update", data).then(function(res){
          self.$router.push({name: 'my-estimates'});
        });
      } else {
        this.$store.dispatch("estimates/save", { item: item }).then(function(res){
          self.$router.push({name: 'estimate-edit', params: { estimate_id: res.id }});
        });
      }
    }
  },
  mounted() {
    if (this.estimateId) {
      this.load(this.$route.params.estimate_id);
    }
  }
};
</script>

<style>
.services-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}
.services-item {
  display: flex;
}
.company-type-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}
.company-type-item {
  display: flex;
}
.services-item-name {
  flex-grow: 2;
}
.company-type-item-name {
  flex-grow: 2;
}
</style>