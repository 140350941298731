<template>
  <div class="register-form-modal">
    <h2>Registro</h2>
      <form novalidate="true">
    
      <label for="email">Correo electrónico</label>
      <input
        type="email"
        v-model="email"
        id="email"
        minlength="6"
        autocomplete="off"
        @change="resetErrors"
        placeholder="Correo electrónico"
        required
      />

      <ul v-if="errors.email" class="errors" role="alert">
        <li v-for="(err, idx) in errors.email" v-bind:key="idx">{{err.message}}</li>
      </ul>

      <label for="password">Contraseña
        <span>
          <password-visibility input="register-password"></password-visibility>
        </span>
      </label>

      <input
        ref="pass"
        id="register-password"
        type="password"
        v-model="password"
        autocomplete="new-password"
        placeholder="Contraseña"
        minlength="8"
        required
      />
      <ul v-if="errors.password" class="errors" role="alert">
        <li v-for="(err, idx) in errors.password" v-bind:key="idx">{{err.message}}</li>
      </ul>
    
      <div class="button-group">
        <button type="button" v-on:click="cancel()">
          Cancelar
        </button>
        <button type="button" v-on:click="register()" class="primary">
          Registro
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import PasswordVisibility from "./PasswordVisibility.vue";

export default {
  name: "RegisterModal",
  id: "register-modal",
  components: {
    PasswordVisibility
  },
  data: function () {
    return {
      errors: {},
      email: "",
      password: "",
      formCss: {
        "was-validated": false
      }
    };
  },
  methods: {
    reject() {
      Promise.reject("Cancelardo");
    },
    cancel() {
      this.reject("Cancelled");
    },
    resetErrors() {
      this.errors = {};
    },
    register() {
      this.resetErrors();
      let self = this;

      var data = {
        email: this.email,
        password: this.password,
      };

      this.$store
        .dispatch("auth/register", data)
        .then(function (msg) {
          if (typeof self.resolve == 'undefined') {
            //self.$router.push({name:'auth-login'})
          } else {
            self.resolve(data);
          }
        })
        .catch(function (errors) {
          self.errors = errors;
        });
    },
  }
}
</script>