<template>
  <div class="reset-view">
    <h1>Blanquear contraseña</h1>
    <reset-form></reset-form>
    <auth-sidebar></auth-sidebar>
  </div>
</template>

<script>
import ResetForm from "../component/ResetForm.vue";
import AuthSidebar from "./AuthSidebarView.vue";

export default {
  id: "LoginView",
  components: {
    AuthSidebar,
    ResetForm
  }
}
</script>
<style>
.reset-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}
.reset-view h2 {
  grid-column: 1;
  grid-row: 1;
}
.reset-view form {
  grid-column: 1;
  grid-row: 2;
}
.reset-view .auth-aside {
  grid-column: 2;
  grid-row: 2;
}
</style>