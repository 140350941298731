export default {
  namespaced: true,
  state: {
    all: [],
    active: null,
    notifications: [],
    messages: [],
    own: []
  },
  mutations: {
    setEstimates(state, estimates) {
      state.all = estimates;
    },
    setEstimate(state, estimate) {
      state.active = estimate;
    },
    setOwnEstimates(state, estimates) {
      state.own = estimates;
    },
    setMessages(state, messages) {
      state.messages = messages;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setTotal(state, { response, index }) {
      if (response.headers["x-total-count"]) {
        state.totals[index] = parseInt(response.headers["x-total-count"]);
      } else {
        state.totals[index] = 0;
      }
    },
    setNotification(state, notification) {
      state.notifications.forEach(function(not, idx){
        if (not.id == notification.id) {
          state.notifications[idx] = notification;
        }
      })
    }
  },
  actions: {
    get({ commit }) {
      //console.log(this, commit);
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`estimates/me`).then(function(response) {
          commit("setEstimates", response.data);
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
    load({ commit }, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`estimates/${data.estimate_id}`).then(function (response) {
          commit("setEstimate", response.data);
          resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    me({ commit }, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`estimates/me`).then(function (response) {
          commit("setOwnEstimates", response.data);
          resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    save({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.post(`estimates`, data.item).then(function(response) {
          //commit("setEstimate", response.data);
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
    update({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        console.log(data);
        self.$http.put(`estimates/${data.estimateId}`, data.item).then(function(response) {
          //commit("setEstimate", response.data);
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
    addService({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        const url = `estimates/${data.estimate_id}/service_types/${data.service_id}`
        self.$http.post(url).then(function(response) {
          //commit("setEstimate", response.data);
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
    addCompanyType({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        const url = `estimates/${data.estimate_id}/company_types/${data.company_type_id}`
        self.$http.post(url).then(function(response) {
          //commit("setEstimate", response.data);
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
    removeService({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        const url = `estimates/${data.estimate_id}/service_types/${data.service_id}`
        self.$http.delete(url).then(function(response) {
          //commit("setEstimate", response.data);
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
    removeCompanyType({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        const url = `estimates/${data.estimate_id}/company_types/${data.company_type_id}`
        self.$http.delete(url).then(function(response) {
          //commit("setEstimate", response.data);
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
    notifications({ commit }, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        //console.log(data);
        self.$http.get(`estimates/${data.estimate_id}/notifications`, { params: data.query }).then(function (response) {
          commit("setNotifications", response.data);
          resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    notification({ commit }, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        //console.log(data);
        self.$http.get(`estimates/${data.estimate_id}/notifications/${data.notification_id}`).then(function (response) {
          commit("setNotification", response.data);
          resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    preview({ commit }, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        //console.log(data);
        self.$http.get(`estimates/${data.estimate_id}/preview`).then(function (response) {
          resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    prepare({ commit }, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        //console.log(data);
        self.$http.post(`estimates/${data.estimate_id}/prepare`).then(function (response) {
          resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    sendNotification({ commit }, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        //console.log(data);
        let url = `estimates/${data.estimate_id}/notifications/${data.notification_id}/send`
        self.$http.post(url).then(function (response) {
          //commit("setNotifications", response.data);
          resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    messages({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        const url = `estimates/${data.estimate_id}/notifications/${data.notification_id}/messages`
        self.$http.get(url, {params:data.query}).then(function(response) {
          commit("setMessages", response.data);
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
  }
};