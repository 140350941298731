<template>
  <div id="add-relation-item">
    <form>
      <input type="text" v-model="search" />
    </form>
    <div id="a" class="grid-list-wrapper">
      <ul class="grid-list">
        <li v-for="o in filtered" :key="o.id">
          <div class="name">
            {{o.name}}
          </div>
          <div class="actions">
            <button type="button" @click="select(o)">{{this.buttonText}}</button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array
    },
    exclude: {
      type: Array
    },
    buttonText: {
      type: String,
      default() {
        return "Agregar";
      }
    }
  },
  data() {
    return {
      search: "",
      selected: this.exclude
    }
  },
  watch: {
    search(neu, old) {
      if (neu != old) {
        this.searchChange(neu)
      }
    }
  },
  computed: {
    filtered() {
      this.selected.forEach(function(item) {
        this.remove(item)
      }, this);
      return this.options;
    }
  },
  methods: {
    remove(item2) {
      this.options.forEach(function(item, idx) {
        if (item.id == item2.id) {
          //console.log('remover', item.name);
          this.options.splice(idx, 1);
        }
      }, this);
    },
    handleInput (e) {
      this.$emit('input', this.content)
    },
    searchChange(e) {
      if (e.length > 2) {
        this.$emit('search-change', e)
      }
    },
    select(e) {
      this.selected.push(e);
      this.$emit('selected', e)
    }
  }
};
</script>
<style>
#add-relation-item .grid-list li {
  grid-template-columns: 3fr 1fr;

}
#add-relation-item .grid-list li div {
  align-self: center;
}
#add-relation-item .grid-list-wrapper {
  margin-top: 1rem;
}
</style>