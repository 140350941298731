export default {
  namespaced: true,
  state: {
    list: [],
    own: [],
    searchResult: []
  },
  mutations: {
    setSearchResult(state, results) {
      state.searchResult = results;
    },
    setList(state, list) {
      state.list = list;
    },
    setOwn(state, results) {
      state.own = results;
    }
  },
  actions: {
    search({ commit }, data) {
      return new Promise(function(resolve, reject) {
        var p = {};
        if (data.text) {
          p.params = { 
            text: data.text
          }
        }
        http.get(`companies`, p).then(function(response) {
          commit("setSearchResult", response.data);
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
    load({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`companies/${data.company_id}`).then(function(response) {
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
    edit({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`companies/${data.company_id}/edit`).then(function(response) {
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
    create({ commit }, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        self.$http.post(`companies`, data.item).then(function (response) {
          resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    update({ commit }, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        self.$http.put(`companies/${data.company_id}`, data.item).then(function (response) {
          resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    own({commit}, data){
      var self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`companies/me`, {}).then(function (response) {
          commit('setOwn', response.data); 
          //resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    list({ commit }, data) {
      //console.log(data);
      var self = this;
      return new Promise(function(resolve, reject) {
        var p = {};
        if (data.text) {
          p.params = { 
            text: data.text
          }
        }
        self.$http.get(`companies`, p).then(function(response) {
          commit("setList", response.data);
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
    addService({ commit }, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        const url = `companies/${data.company_id}/services/${data.service_id}`;
        self.$http.post(url).then(function (response) {
          resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    loadServices({ commit }, data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        const url = `companies/${data.company_id}/services`;
        self.$http.get(url).then(function (response) {
          resolve(response.data);
        }).catch(function (err) {
          console.log(err);
        });
      })
    },
    removeService({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        const url = `companies/${data.company_id}/services/${data.service_id}`;
        self.$http.delete(url).then(function(response) {
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
  }
};