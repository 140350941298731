import companyStore from "./stores/company";
import companyTypeStore from "./stores/companyType";

import CompanyList from "./views/CompanyList.vue";
import CompanyNew from "./views/CompanyNew.vue";
import MyCompanies from "./views/MyCompanies.vue";
import CompanyDetail from "./views/CompanyDetail.vue";

var routes = [
  {
    path: "/companies",
    name: "companies",
    component: CompanyList,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/companies/me",
    name: "my-companies",
    component: MyCompanies,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/companies/new",
    name: "company-new",
    component: CompanyNew,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/companies/:company_id/edit",
    name: "company-edit",
    component: CompanyNew,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/companies/:company_id",
    name: "company-detail",
    component: CompanyDetail,
    meta: {
      requiresAuth: false
    }
  }
];

var stores = {
  company: companyStore,
  companyType: companyTypeStore
}

export default {
  installed: false,
  install(app, options) {
    if (this.installed) {
      return;
    }

    options.store.registerModule('company', companyStore);
    options.store.registerModule('companyType', companyTypeStore);

    routes.forEach(function(route) {
      options.router.addRoute(route);
    })
  }
};

export { routes, stores }