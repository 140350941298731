<template>
  <div id="estimates">
    <h1>Presupuestos</h1>
    <div class="content">
      <ul class="grid-list">
        <li class="estimate-item" v-for="e in estimates" :key="e.id">
          <div class="estimate-item-title">
            <router-link
              :to="{ name: 'detail', params: { estimate_id: e.id } }"
            >
              {{ e.subject }}
            </router-link>
          </div>
          <div class="estimate-item-abstract">
            {{ e.abstract }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Estimate",
  computed: {
    estimates() {
      return this.$store.state.estimates.all;
    },
  },
  methods: {
    create() {
      this.$router.push({ name: "estimate-new" });
    },
    load() {
      this.$store.dispatch("estimates/get");
    }
  },
  mounted() {
    this.load();
  }
};
</script>