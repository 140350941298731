export default {
  namespaced: true,
  state: {
    all: [],
    searchResult: []
  },
  mutations: {
    setAll(state, items) {
      state.all = items;
    },
    setSearchResult(state, results) {
      state.searchResult = results;
    },
  },
  actions: {
    search({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`company_types`, { params: { text: data.text }}).then(function(response) {
          commit("setSearchResult", response.data);
          resolve(response.data);
        }).catch(function(err) {
          console.log(err);
        });
      })
    },
  }
};