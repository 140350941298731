<template>
  <div id="address-edit">
    <h2>Editar dirección</h2>
    <form>
        <label>Nombre (descripcion)</label>
        <input type="text" v-model="item.title" />
        
        <label>Calle / Numero</label>
        <div class="input-group">
          <input type="text" v-model="item.street" />
          <input type="text" v-model="item.number" />
        </div>

        <label>Piso / Departamento</label>
        <div class="input-group">
          <input type="text" v-model="item.floor" />
          <input type="text" v-model="item.department" />
        </div>

        <label>Pais</label>
        <select
          v-if="countries"
          v-model="item.country_id"
          v-on:change="updateProvinces()"
        >
          <option
            v-for="c in countries"
            v-bind:key="c.id"
            v-bind:value="c.id"
          >
            {{ c.name }}
          </option>
        </select>
        <label>Provincia</label>
        <select
          v-if="provinces"
          v-model="item.province_id"
          v-on:change="updateCities()"
        >
          <option
            v-for="p in provinces"
            v-bind:key="p.id"
            v-bind:value="p.id"
          >
            {{ p.name }}
          </option>
        </select>
        <label>Ciudad</label>
        <select v-if="cities" v-model="item.city_id">
          <option
            v-for="c in cities"
            v-bind:key="c.id"
            v-bind:value="c.id"
          >
            {{ c.name }}
          </option>
        </select>
        <div class="button-group">
          <button type="button" @click="close()">Cerrar</button>
          <button type="button" class="primary" v-on:click="save()">Guardar</button>
        </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "AddressAddEditForm",
  id: "address-add-edit-form",
  props: {
    address: {
      type: Object,
      required: false,
      default(rawProps) {
        //console.log(rawProps);
        return {
          country_id: 0,
          province_id: 0,
          city_id: 0,
        }
      }
    }
  },
  data() {
    return {
      item: {
        title: this.address.title || "",
        street: this.address.street || "",
        number: this.address.number || "",
        floor: this.address.floor || "",
        department: this.address.department || "",
        country_id: this.address.country?.id || 0,
        province_id: this.address.province?.id || 0,
        city_id: this.address.city?.id || 0,
      }
    }
  },
  computed: {
    countries() {
      return this.$store.state.address.countries;
    },
    provinces() {
      return this.$store.state.address.provinces;
    },
    cities() {
      return this.$store.state.address.cities;
    },
  },
  methods: {
    close() {
      this.reject("Closed");
    },
    updateProvinces() {
      this.$store.dispatch("address/provinces", {
        country_id: this.item.country_id,
      });
    },
    updateCities() {
      this.$store.dispatch("address/cities", this.item);
    },
    save() {
      var self = this;
      if (this.address.id) {
        this.$store.dispatch("address/update", {
            address_id: this.address.id,
            item: this.item 
          }).then(function(msg){
            self.resolve(msg);
        });
      } else {
        this.$store.dispatch("address/save", { item: this.item }).then(function(msg){
          self.resolve(msg);
        });
      }
    },
  },
  mounted() {
    console.log(this.item);
    this.$store.dispatch("address/countries");
    if (this.item.country_id) {
      this.updateProvinces();
    }
    if (this.item.province_id) {
      this.updateCities();
    }
  },
};
</script>