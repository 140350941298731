import LayerModal from "./components/LayerModal.vue";

export default {
  installed: false,
  install(app, options) {
    if (this.installed) return;

    if (typeof app.config.globalProperties.$emitter === 'undefined') {
        console.warn('Se necesita app.config.globalProperties.$emitter');
    }

    options.router.beforeEach((to, from) => {
      //console.log("layerModal", to, from);
      if (to.fullPath !== from.fullPath) {
        app.config.globalProperties.$emitter.emit('CLOSE_ALL_LAYER_MODAL');
      }
      return true;
    })

    app.component("layer-modal", LayerModal);

    app.config.globalProperties.$openModal = function(opts) {
      //console.log('app.config.globalProperties.$openModal', opts);
      
      let a = app.component(opts.component.id);
      if (typeof a === 'undefined') {
        app.component(opts.component.id, opts.component);
      }

      return new Promise(function(resolve, reject) {
        opts.resolve = resolve;
        opts.reject = reject;
        //opts.component.methods.resolve = resolve;
        //opts.component.methods.reject = resolve;

        //var comp = app.component("layer-modal");
        //console.log(comp);
        //comp.methods.append(comp);

        app.config.globalProperties.$emitter.emit('OPEN_LAYER_MODAL', opts);
      });
    };

    this.installed = true;
  }
};
