import http from "../http";

export default {
  namespaced: true,
  state: {
    countries: [],
    provinces: [],
    cities: [],
    all: [],
    me: []
  },
  mutations: {
    setCountries(state, data) {
      state.countries = data;
    },
    setProvinces(state, data) {
      state.provinces = data;
    },
    setCities(state, data) {
      state.cities = data;
    },
    setAddresses(state, data) {
      state.all = data;
      state.me = data;
    }
  },
  actions: {
    countries({ commit }) {
      return new Promise(function(resolve, reject) {
        http.get(`/countries`).then(function(response) {
          commit("setCountries", response.data);
          resolve(response.data);
        });
      })
    },
    provinces({ commit }, data) {
      return new Promise(function(resolve, reject) {
        http.get(`/countries/${data.country_id}/provinces`).then(function(response) {
          commit("setProvinces", response.data);
          resolve(response.data);
        });
      })
    },
    cities({ commit }, data) {
      return new Promise(function(resolve, reject) {
        http.get(`/countries/${data.country_id}/provinces/${data.province_id}/cities`).then(function(response) {
          commit("setCities", response.data);
          resolve(response.data);
        });
      })
    },
    save({ commit }, data) {
      return new Promise(function (resolve, reject) {
        http.post(`/addresses`, data.item).then(function (response) {
          resolve(response.data);
        });
      })
    },
    update({ commit, dispatch }, data) {
      return new Promise(function (resolve, reject) {
        http.put(`/addresses/${data.address_id}`, data.item).then(function (response) {
          resolve(response.data);
          dispatch('load');
          dispatch('notifications/success', {msg: 'Direccion actualizada'}, {root: true});
        });
      })
    },
    load({ commit }, data) {
      //console.log(http);
      // { baseURL: baseURL }
      //console.log(this);
      //var self = this;
      //console.log(http.defaults.baseURL);// = 'hola';
      return new Promise(function(resolve, reject) {
        http.get(`/addresses/me`).then(function(response) {
          commit("setAddresses", response.data);
          resolve(response.data);
        });
      })
    }
  }
};

export { http }