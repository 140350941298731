
<template>
  <form autocomplete="false">
    <div>
      <label for="username" class="form-label">Nombre de perfil</label>
      <input type="text" class="form-control" v-model="item.name" id="username">
    </div>
    <div>
      <label for="telegram" class="form-label">Telegram (publico)</label>
      <input type="text" class="form-control" v-model="item.telegram" id="telegram" name="telegram">
    </div>
    <div class="actions mt-4">
      <button type="button" @click="submit" class="btn btn-primary">Enviar</button>
    </div>
  </form>
</template>

<script>
export default {
  name: "UserProfileForm",
  id: 'user-profile-form',
  props: {
    telegram: {
      type: Boolean
    }
  },
  data() {
    return {
      item: {
        name: ''
      }
    }
  },
  methods: {
    submit() {
      var self = this;
      this.$store.dispatch('profile/update', { item: this.item }).then(function(item){
        self.$emit('resolve', 'OK');
      })
    }
  },
  mounted() {
    //console.log("mounted");
    var self = this;
    this.$store.dispatch('profile/load').then(function(item){
      console.log(item);
      self.item.name = item.name;
    })
  }
}
</script>