<template>
  <div id="estimate-messages">
    <page-title title="Mensajes">

    </page-title>
    <page-content>
      <ul class="grid-list">
        <li  v-for="m in messages" :key="m.id">
          <pre>{{m.message}}</pre>
        </li>
      </ul>
    </page-content>
  </div>
</template>

<script>

export default {
  components: { },
  name: "EstimateMessageList",
  computed: {
    messages() {
      return this.$store.state.estimates.messages;
    }
  },
  methods: {
    load() {
      this.$store.dispatch('estimates/messages', {
        estimate_id: this.$route.params.estimate_id,
        notification_id: this.$route.params.notification_id,
      })
    }
  },
  mounted() {
    this.load();
  }
};
</script>
