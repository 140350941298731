<template>
  <div id="estimate-notifications">
    <page-title title="Notificaciones">
      <template v-slot:actions>
        <button @click="onlyWithResponses()">Mostrar solo con respuestas</button>
      </template>
    </page-title>
    <page-content>
      <ul class="grid-list">
        <li v-for="notif in notifications" :key="notif.id">
          <div class="company">
            {{notif.company.name}}
          </div>
          <div class="status">
            {{notif.status.value}}
          </div>
          <div class="sent">
            {{notif.sent_at}}
          </div>
          <div class="actions">
            <button v-if="notif.sent_at === 0" class="primary" @click="send(notif.id)">Enviar</button>
            <router-link class="button" :to="{name:'estimate-message-list', params: {estimate_id:estimateId, notification_id:notif.id}}">Ver mensajes</router-link>
          </div>
        </li>
      </ul>
    </page-content>
  </div>
</template>

<script>

export default {
  components: { },
  name: "EstimateNotifications",
  data() {
    return {

    }
  },
  computed: {
    notifications() {
      return this.$store.state.estimates.notifications;
    },
    estimateId() {
      return this.$route.params.estimate_id;
    }
  },
  methods: {
    load(id, query) {
      this.$store.dispatch('estimates/notifications', { estimate_id: id, query: query});
    },
    send(notificationId) {
      let self = this;
      let payload = { 
        estimate_id: this.estimateId,
        notification_id: notificationId
      };
      this.$store.dispatch('estimates/sendNotification', payload).then(function(){
        self.$store.dispatch('estimates/notification', payload);
      });
    },

    onlyWithResponses() {
      const query = Object.assign({}, this.$route.query);
      query['has_replies'] = "1";
      //this.$router.push({'name': 'Bla'});
      var self = this;
      this.$router.push({query}).then(function(){
        //self.load(self.$route.params.id);
      })
    }
  },
  beforeRouteUpdate(to, from, next) {
    //console.log('beforeRouteUpdate');
    this.load(to.params.estimate_id, to.query);
    next();
  },
  mounted() {
    //console.log(this.$route);
    this.load(this.$route.params.estimate_id, this.$route.query);
  }
};
</script>

<style>
#estimate-notifications .grid-list li {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
</style>