<template>
  <div id="system-area">
    <component v-for="applet in applets" :key="applet" :is="applet"></component>
  </div>
</template>

<script>
export default {
  name: "SystemArea",
  computed: {
    visible() {
      return this.$store.getters['auth/isAuthed'];
    },
    applets() {
      var applets = this.$store.state.systemArea.applets
      var show = [];
      applets.forEach(function(applet, i) {
        //console.log(applet);
        if (applet.requireAuth === true && this.visible) {
          show.push(applet)
        }
        if (applet.requireAuth === false) {
          show.push(applet)
        }
      }, this)
      
      return show;
    }
  }
};
</script>

<style>

</style>