export default {
  namespaced: true,
  state: {
    applets: []
  },
  mutations: {
    add(state, applet) {
      state.applets.push(applet);
    }
  },
  actions: {
    add({ commit }, payload) {
      commit("add", payload);
    }
  }
};
