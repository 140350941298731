<template>
  <div>
    <div v-if="open" 
      class="backdrop"
      v-bind:class="backdropClass"
      @click="onBgClicked"
    ></div>
      <div
        v-for="modal in modals"
        :class="getClasses(modal)"
        class="layer-modal"
        :style="modal.style"
        :key="modal.component.id"
      >
          <component
            v-bind:is="modal.component.id"
            v-bind="modal.props"
          ></component>
      </div>
  </div>

</template>

<script>
export default {
  name: "LayerModal",
  data() {
    return {
      props: {},
      modals: []
    };
  },
  computed: {
    /*modals() {
      //return this.$store.state.modals.modals;
      return this.modals;
    },*/
    backdropClass() {
      let cssClass = "";

      if (this.modals.length) {
        //cssClass = ` level${this.$store.state.modals.modals.length}`;
        cssClass = ` level${this.modals.length}`;
      }

      return cssClass;
    },
    level() {
      //console.log('level(): ' + this.modals.length);
      return this.modals.length + 1;
      //return this.$store.state.modals.modals.length + 1;
    },
    open() {
      //var l = this.$store.state.modals.modals.length;
      //var open = this.$store.state.modals.modals.length > 0;
      //return open;
      return this.modals.length > 0;
    }
  },
  created() {
    var vm = this;
    //console.log(vm.$emitter);

    vm.$emitter.on("OPEN_LAYER_MODAL", function(data) {
      //console.log('created() OPEN_LAYER_MODAL', data);
      data.visible = true;

      if (typeof data.cssClass === 'undefined') {
        data.cssClass = ["open-on-right"];
      }

      if (typeof data.component.id === 'undefined') {
        console.warn("component.id missing");
      }

      data.cssClass.push(`level${vm.level}`);

      document.body.classList.add('layer-modal-open');

      data.component.methods.resolve = function(payload) {
        vm.close();
        data.resolve(payload);
      };

      data.component.methods.reject = function(payload) {
        vm.close();
        data.reject(payload);
      };

      vm.append(data);
    });

    vm.$emitter.on("CLOSE_ALL_LAYER_MODAL", function() {
      vm.modals = [];
    });

    document.addEventListener("keydown", this.onEscapeKeypress);
  },
  methods: {
    updateWidths(data) {
      console.log('updateWidths')
      /*      var w = vm.getWidth(data);
      data.style = {
        width: `${w}%`
      };*/
      var firstSet = false;
      this.modals.forEach(function(m, idx){
        if (m.width) {
          firstSet = m.width;
        }
      });

      if (firstSet === false) {
        firstSet = 50;
      }

      var q = this.modals.length;

      this.modals.forEach(function(m, idx) {
        q--;
        let value = firstSet + (5 * q);
        if (!m.style) {
          m.style = {};
        }
        m.style.width = `${value}%`;
      });
    },
    append(component) {
      this.modals.push(component);
      this.updateWidths();
    },
    getClasses(modal) {
      //console.log(modal);
      const css = {};
      if (modal.cssClass) {
        modal.cssClass.forEach(function(cls) {
          css[cls] = true;
        });
      } else {
        
      }

      let level = this.level - 1;
      if (level > 1) {
        css[`max-level-${level}`] = true;
      }

      return css;
    },
    /*closeModal(name) {
      console.log('closeModal', name);
      this.modals.forEach(function(modal, idx) {
        if (modal.component.name === name) {
          this.modals.splice(idx, 1);
        }
      }, this);
      this.updateWidths();
    },*/
    onEscapeKeypress(e) {
      if (e.keyCode === 27) {
        console.log('LayerModal: onEscapeKeypress');
        this.close();
      }
    },
    onBgClicked() {
      console.log('onBgClicked');
      this.close();
    },
    close() {
      this.modals.splice(this.modals.length - 1, 1);

      if (this.modals.length === 0) {
        document.body.classList.remove('layer-modal-open');
      }

      this.updateWidths();
    }
  },
  unmounted() {
    document.removeEventListener("keydown", this.onEscapeKeypress);
  }
};
</script>
<style>
.backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  overflow: hidden;
}
.layer-modal.level1 {
  z-index: 1046;
}
.layer-modal.level2 {
  z-index: 1048;
}
.layer-modal-container {
  display: block;
}
.layer-modal-wrapper {
  position: static;
}
.layer-modal-open {
  overflow: hidden;
}
.open-on-left {
  right: auto;
  left: 0;
  border-right: 2px solid rgb(12, 104, 238);
}
.open-on-right {
  right: 0;
  left: auto;
  border-left: 2px solid rgb(12, 104, 238);
}
.layer-modal {
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 1rem;
  overflow-y: auto;
  /*background: var(--background-color);*/
  /*background: white;*/
  background-color: #282520;
  width: 50%;
  min-width: 320px;
}
.modal-content-wrapper {

}
/*fieldset.actions, fieldset.action {
  display: grid;
  grid-auto-flow: column;
}
fieldset.actions {
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}
fieldset.action {
  padding-top: 1rem;
  grid-template-columns: 100%;
}*/
.layer-modal.level1.max-level-2 {
  width: 60%;
}
.layer-modal.level1.max-level-3 {
  width: 70%;
}
.layer-modal.level2.max-level-3 {
  width: 60%;
}
.layer-modal.level1.max-level-2, .layer-modal.level1.max-level-3 {
  border-left: 2px solid #ccc;
}
.layer-modal.level2.max-level-3 {
  border-left: 2px solid #ccc;
}
.layer-modal-open header a, .layer-modal-open .main-wrapper a {
  color: rgb(33, 37, 41);
}
.backdrop.level1 {
  z-index: 1045;
}
.backdrop.level2 {
  z-index: 1047;
}
.backdrop.level3 {
  z-index: 1049;
}
.layer-modal.level2 {
  z-index: 1048;
}
.layer-modal.level3 {
  z-index: 1050;
}
</style>