<template>
  <div class="new-password-modal">
    <h2>Nueva clave</h2>
    <form>
        <label>Codigo</label>
        <input
          type="text"
          v-model="code"
          placeholder="Codigo enviado x correo"
        />

        <label>Contraseña
          <span>
            <password-visibility input="password1"></password-visibility>
          </span>
        </label>

        <input
          type="password"
          minlength="8"
          id="password1"
          v-model="password"
        />
        <label class="my-2">Repetir contraseña
          <span class="ms-auto">
            <password-visibility input="password2"></password-visibility>
          </span>
        </label>
        <input
          type="password"
          minlength="8"
          id="password2"
          v-model="password2"
          class="form-control"
        />

      <div class="button-group">
        <button
            type="button"
            v-on:click="cancel()"
          >
            Cancelar
          </button>
          <button type="button" v-on:click="save()" class="primary">Guardar</button>
      </div>
    </form>
  </div>
</template>

<script>
//import store from "@/store";
import PasswordVisibility from "./component/PasswordVisibility.vue";

export default {
  name: "NewPassModal",
  id: "new-pass-modal",
  components: {
    PasswordVisibility
  },
  data() {
    return {
      el: "newpass",
      errors: [],
      code: "",
      password: "",
      password2: "",
    };
  },
  props: {
    email: {
      type: String,
    },
  },
  methods: {
    cancel() {
      this.reject("Cancelled new pass");
    },
    save() {
      if (this.password != this.password2) {
        //console.log("los pass no coinciden");
        return;
      }
      if (this.password == "" || this.password2 == "") {
        //console.log("los pass estan vacios");
        return;
      }
      this.errors = [];
      var self = this;
      self.$store
        .dispatch("auth/resetsave", {
          email: this.email,
          code: this.code,
          password: this.password,
        })
        .then(function (msg) {
          //console.log("Login OK");
          self.resolve(msg);
        })
        .catch(function (errors) {
          self.errors = errors;
        });
    },
  },
};
</script>
