<template>
  <div class="reset-form-modal modal">
    <h2>Resetear clave</h2>
    <form>
      <div class="alert alert-info" role="alert" v-if="errors.length">
        <div v-for="err in errors" v-bind:key="err.id">{{ err }}</div>
      </div>
        <label>Correo electrónico</label>
        <input
          type="email"
          v-model="email"
          autocomplete="username"
          placeholder="Usuario o correo electrónico"
        />
      <div class="button-group">
        <button type="button" v-on:click="cancel()">
          Cancelar
        </button>
        <button type="button" class="primary" v-on:click="reset2()">
          Enviar código
        </button>
      </div>
    </form>
  </div>
</template>
<script>

export default {
  name: "ResetForm",
  id: "reset-form",
  data() {
    return {
      el: "reset",
      errors: [],
      email: "@"
    };
  },
  props: {
    mail: {
      type: String
    }
  },
  methods: {
    cancel() {
      this.reject("Cancelled");
    },
    reset() {
      if (!this.email) {
        alert("No hay mail");
        return false;
      }

      var obj = {
        props: {
          email: this.email
        }
      };

      var self = this;
      self.$store
        .dispatch("auth/reset", {
          email: self.email
        })
        .then(function(msg) {
          var obj = {
            props: {
              email: self.email
            }
          };
          self.$auth.newPassModal(obj).then(function(res) {
            self.resolve(res);
          }).catch(function(err) {
            console.log(err);
          });
        }).catch(function(errors) {
          self.errors = errors;
        });;
    },
    reset2() {
      this.errors = [];
      var self = this;
      self.$store
        .dispatch("auth/reset", {
          email: self.email
        })
        .then(function(msg) {
          var obj = {
            props: {
              email: self.email
            }
          };
          self.$auth.newPassModal(obj).then(function(res){
            self.resolve(res);
          });
          //self.resolve(msg);
        }).catch(function(errors) {
          self.errors = errors;
        });;
    }
  }
};
</script>
