import { createRouter, createWebHistory } from "vue-router";

import Home from "@/components/HomeHola.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
];

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
