import UserProfileForm from "./component/UserProfileForm.vue";
import Settings from "./views/Settings.vue";

export default {
  requireAuth: false,
  computed: {
    userId: function() {
      return this.$store.getters["auth/userId"];
    },
    isAuthed: function() {
      return this.$auth.isAuthed();
    },
    username: function() {
      var name = 'Anonimo';
      if (this.$store.getters['auth/isAuthed']) {
        if (this.$store.getters['profile/username']) {
          name = this.$store.getters['profile/username'];
        } else {
          this.$store.dispatch('profile/me');
        }
      }
      return name;
    },
    menu: function() {
      var submenu = [];

      if (this.$auth.isAuthed()) {
        submenu.push({ title: 'Configuración', method: 'settings' });
        submenu.push({ title: 'Cerrar sesión', method: 'logout' });
      } else {
        submenu.push({ title: 'Ingresar', method: 'login'});
        submenu.push({ title: 'Registrarse', method: 'register' });
      }

      //console.log('this', this, this.username);

      return [{
          title: "",
          route: null,
          icon: 'bi bi-person',
          position: 'end',
          menu: submenu
        }];
    }
  },
  data() {
    return {};
  },
  methods: {
    settings() {
      this.$openModal({
        component: Settings,
        width: 75
      });
    },
    offcanvas: function() {
      //console.log('user.off', UserProfileForm);
      this.$offcanvas(UserProfileForm, { 
        title: 'Actualizar perfil',
        position: 'end',
        props: {
          telegram: true
        }
      });
    },
    logout: function() {
      this.$store.dispatch("auth/logout");
      if (this.$route.meta.requiresAuth) {
        this.$router.push('/');
      }
    },
    login: function() {
      //this.$router.push('/login')
      //console.log('login');
      this.$auth
        .loginModal()
        .then(function() {
          console.log("UserApplet:login:ok");
        })
        .catch(function(err) {
          console.log("UserApplet:login:error", err);
        });
    },
    register: function() {
      var self = this;
      this.$auth
        .registerModal()
        .then(function(data) {
          self.$auth.verifyModal({ props: data }).then(function(data){
            console.log(data);
          }).catch(function(err) {
            console.log(err);
          });
        })
        .catch(function(err) {
          console.log("UserApplet:register:error", err);
        });
    }
  }
};