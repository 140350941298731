<template>
  <div id="estimate-add-service-type">
    <modal-title title="Agregar servicio"> </modal-title>
    <add-relation-item
      :options="options"
      :exclude="exclude"
      @search-change="asyncFind"
      @selected="selected"
    ></add-relation-item>
  </div>
</template>

<script>
import { AddRelationItem } from "@linkedcode/common-lib";

export default {
  name: "EstimateAddServiceTypeModal",
  id: "estimate-add-service-type-modal",
  components: {
    AddRelationItem
  },
  props: {
    estimateId: {
        type: Number,
        required: true
    },
    exclude: {
        type: Array,
        default: function() {
            return [];
        }
    }
  },
  data() {
    return {
        options: []
    }
  },
  methods: {
    asyncFind(text) {
      if (text.length < 2) {
        return false;
      }
      var self = this;
      this.$store.dispatch('services/search', { text: text }).then(function(res){
        self.options = res;
      });
    },
    selected(service) {
      var body = { 
        estimate_id: this.estimateId,
        service_id: service.id
      }
      var self = this;
      this.$store
        .dispatch('estimates/addService', body)
        .then(function(item){}
      );
    },
  },
};
</script>