<template>
  <div class="login-form-modal">
    <h2>Ingresar</h2>
    <form>
      <div class="error" role="alert" v-if="error">
        {{error.error_description}}
      </div>
      <label>Correo electrónico</label>
      <input
        type="email"
        v-model="email"
        class="form-control"
        autocomplete="off"
        data-autocomplete="username"
        placeholder="Correo electrónico"
      />
      <label>Contraseña
        <span>
          <password-visibility input="login-password"></password-visibility>
        </span>
      </label>
      <input
        type="password"
        id="login-password"
        v-model="password"
        autocomplete="off"
        data-autocomplete="current-password"
        class="form-control"
        placeholder="Contraseña"
      />
      <div class="button-group">
        <button
          type="button"
          v-on:click="cancel()"
        >
          Cancelar
        </button>
        <button type="button" @click="login()" class="primary">Iniciar Sesion</button>
      </div>
      <h3>¿Olvido su clave?</h3>
      <p>Si no recuerda su clave, <a v-on:click="reset()">resetee su clave</a>.</p>
    </form>
  </div>
</template>
<script>
import PasswordVisibility from "./PasswordVisibility.vue";
import ResetModal from "./ResetForm.vue";

export default {
  name: "LoginForm",
  id: "login-form",
  components: {
    PasswordVisibility,
    ResetModal
  },
  data() {
    return {
      el: "login",
      errors: [],
      error: false,
      email: "",
      password: ""
    };
  },
  methods: {
    cancel() {
      this.reject("Cancelled");
    },
    login() {
      this.error = false;
      this.errors = [];
      var self = this;
      self.$store
        .dispatch("auth/login", {
          email: this.email,
          password: this.password
        })
        .then(function(jwt) {
          self.resolve(jwt);
        }).catch(function(err) {
          console.log(err);
          self.error = err.response.data;
          console.log(self.error);
          var self2 = self;
          if (err.response.status == 401) {
            self2.$auth.verifyModal({
              props: {
                email: self.email,
                password: self.password,
                code: ""
              }
            }).then(function(jwt) {
              self2.resolve(jwt);
              self.resolve(jwt);
            });
          }
        });;
    },
    reset() {
      var props = {};
      if (this.email != "") {
        props.email = this.email;
      }
      this.$openModal({
        component: ResetModal,
        props: props
      });
    }
  }
};
</script>