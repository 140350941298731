<template>
  <div class="input-group">
    <select class="form-select" @change="selectedAddress" v-model="addressId">
      <option value="0">- Seleccione o cree una nueva -</option>
      <option v-for="a in addresses" v-bind:key="a.id" v-bind:value="a.id">
        {{ a.title }} - {{ a.street }} {{ a.number }}
      </option>
    </select>
    <button
      type="button"
      class="primary"
      v-on:click="newAddress()"
    >
      Crear
    </button>
  </div>
</template>

<script>
import AddressAddEditForm from "../components/AddressAddEditForm.vue";

export default {
  data() {
    return {
      addressId: this.selected,
    };
  },
  props: {
    selected: {
      type: Number,
      required: true,
      validator(value) {
        console.log(value);
        return true;
      },
      default(value) {
        console.log("0def", value);
        return 0;
      },
    },
  },
  methods: {
    selectedAddress() {
      this.$emit("selectedAddress", this.addressId);
    },
    load() {
      this.$store.dispatch("address/load");
      ///console.log(this.addressId);
      //console.log(this.selected);
    },
    newAddress() {
      var self = this;
      this.$openModal({
        component: AddressAddEditForm,
        cssClass: ["open-on-right"],
      }).then(function () {
        self.load();
      });
    },
  },
  computed: {
    addresses() {
      return this.$store.state.address.all;
    },
  },
  created() {
    this.$watch("selected", (newVal, b) => {
      this.addressId = newVal;
    });
  },
  mounted() {
    this.load();
  },
};
</script>