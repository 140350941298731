import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import AuthPlugin from "@linkedcode/auth";
import AxiosPlugin from "@linkedcode/axios-plugin";
import AppStore from "@linkedcode/app-store-plugin";
import NavbarMenu from "@linkedcode/navbar-menu";
import LayerModal from "@linkedcode/layer-modal";
import CompaniesLib from "@linkedcode/companies-lib";
import PresupuestosApp from "@linkedcode/presupuestos-app";
import AddressPlugin from "@linkedcode/address-plugin";
import mitt from "mitt";
import SystemArea from "@linkedcode/system-area";
import BaseDesign from "@linkedcode/design";
import config from "@/config/config";
// Applets
import UserApplet from "@linkedcode/auth/src/component/UserApplet.vue";

const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.$emitter = emitter;

app.use(AxiosPlugin, { api: { url: config.API_URL }, store });
app.use(SystemArea, { store });
app.use(AuthPlugin, { store, router });
app.use(NavbarMenu);
app.use(LayerModal, { router });
app.use(AppStore, { store, router });
app.use(AddressPlugin, { store });
app.use(CompaniesLib, { store, router });
app.config.globalProperties.$appStore.add(PresupuestosApp);
//app.config.globalProperties.$appStore.add(LimeApp);
app.use(store);
app.use(router);
app.config.globalProperties.$appStore.activate(PresupuestosApp);

//app.config.globalProperties.$systemArea.add(NotificationApplet);
app.config.globalProperties.$systemArea.add(UserApplet);
app.use(BaseDesign);

app.mount("#app");
